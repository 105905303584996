import styled from "styled-components";

export const ContentLayout = styled.div`
    width : 100%;
    height: 100%;
    display: grid;

    background-color : #F8F9FA;
    padding-bottom: 20px;
    margin-left: 30px;


`;
